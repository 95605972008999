<template>
    <div class="container repair-container" v-if="asset">

        <div class="row">
            <div class="col-9">
                <h3 class="mt-4">
                    Upload Photos
                </h3>
            </div>
            <div class="col-3">
                <div class="">
                    <div class="card-link-container">
                        <input type="file" name="frontPhoto" id="frontPhoto" class="file-input" accept="image/*"
                               capture="environment"
                               @change="compressAndAddPhoto('after-front', 'frontPhoto')">
                        <label class="card card-body card-link no-icon text-sm p-3 flex-row bg-gradient-info"
                               for="frontPhoto">
                            <icon-device-front
                              size="md"
                              class="icon-all-white m-0 mr-2">
                            </icon-device-front>
                            Add Photo
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <!-- Links -->


        <div class="alert alert-warning">
            <animated-warning></animated-warning>
            <div class="alert-content">
                Please supply photos of the device powered on
            </div>
        </div>

        <!-- Empty state -->
        <div class="card card-body" v-if="!imagePaths.length">
            <empty-state
              emptyIcon
              icon="camera"
              title="No photos uploaded"
              subtitle="Select one of the options above to add a photo">
            </empty-state>
        </div>

        <!-- List of photos -->
        <template>
            <div class="card-grid grid-col-2 grid-col-md-3">
                <image-item
                  v-for="(imagePath, key) in imagePaths" :key="key"
                  :imageType="imagePath.type"
                  :imageData="imagePath.path">
                </image-item>
            </div>
        </template>

        <!-- Footer -->
        <div class="card card-body" v-if="imagePaths.length">
            <button type="button" @click="goBackToAssetPage" class="btn btn-light">Scan another Asset</button>
        </div>

        <!-- Image preview modal -->
        <repair-image-modal></repair-image-modal>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import {firebase} from '@firebase/app';
import '@firebase/storage';
import {v4 as uuidv4} from 'uuid';
import Compressor from 'compressorjs';

export default {

    props: ['assetId'],

    data() {
        return {
            imagePaths: [],
        }
    },

    mounted() {
        this.loadAsset({
            assetId: this.assetId,
        });
        //   .then(() => {
        //
        // });

        this.listImages();
    },

    computed: {
        ...mapGetters([
            "asset",
        ]),

        jobId() {
            if (this.asset) {
                return this.asset.job_id;
            }
            return null;
        },

        deviceId() {
            if (this.asset) {
                return this.asset.owner_id;
            }
            return null;
        },
    },

    methods: {
        ...mapActions([
            "loadAsset",
            "addDeviceImage",
            "displayToast",
        ]),

        compressAndAddPhoto(type, elementId) {
            const filePicker = document.getElementById(elementId);

            if (!filePicker || !filePicker.files || filePicker.files.length <= 0) {
                this.displayToast({text: 'No file selected.', type: 'error'});
                return;
            }

            let self = this;
            new Compressor(filePicker.files[0], {
                quality: 0.6,
                success(result) {

                    self.addPhoto(result, type, elementId);

                },
                error() {
                    self.displayToast({text: 'Could not parse file.', type: 'error'});
                    filePicker.value = "";
                },
            });
        },

        /** Add a new photo */
        addPhoto(myFile, type, elementId) {
            return new Promise(async (resolve) => {

                this.displayToast({text: 'File is being uploaded.', type: 'success'});

                this.displayImage(myFile)
                  .then(result => {
                      this.imagePaths.push({
                          path: result,
                          type: type
                      });
                  });

                let uuid = `${uuidv4()}|${type}`;

                this.addDeviceImage({
                    jobId: this.jobId,
                    deviceId: this.deviceId,
                    type: type,
                    filename: uuid
                });

                const ref = firebase.storage().ref(`/images/${this.deviceId}/${uuid}`);

                await ref.put(myFile);

                this.listImages();

                document.getElementById(elementId).value = "";

                resolve();
            });
        },

        /** Display the image just taken using base64data */
        displayImage(myFile) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                if (fileReader && myFile) {
                    fileReader.readAsDataURL(myFile);
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };

                    fileReader.onerror = (error) => {
                        reject(error);
                    };
                } else {
                    reject('No file provided');
                }
            });
        },

        /** List images from firebase storage */
        listImages() {

            const listRef = firebase.storage().ref(`/images/${this.deviceId}`);

            let self = this;

            listRef.listAll().then(function (res) {

                let urls = [];

                res.items.forEach(function (itemRef) {

                    let splitPath = itemRef.fullPath.split("|");
                    let type = "other";

                    if (splitPath[1]) {
                        type = splitPath[1];
                    }

                    urls.push({
                        path: `https://firebasestorage.googleapis.com/v0/b/${itemRef.bucket}/o/${encodeURIComponent(itemRef.fullPath)}?alt=media&token=${uuidv4()}`,
                        type: type
                    });
                });

                self.imagePaths = urls;

            }).then(() => {
                this.imagePaths.forEach(image => {
                    if (image.type === 'after-fenrir-svc') {
                        this.displayFenrirSVCBanner = false;
                    }

                    if (image.type === 'after-oqc-results') {
                        this.displayAfterTestBanner = false;
                    }

                    if (image.type === 'before-esd-results') {
                        this.displayBeforeTestBanner = false;
                    }
                });
            }).catch(function (error) {
                console.error(error);
            });
        },

        goBackToAssetPage() {
            return this.$router.push({
                name: 'bulkRepairImeiSearch',
                params: {
                    assetId: this.asset.id
                }
            });
        },
    }
}
</script>

<style scoped>

</style>